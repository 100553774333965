import {Interceptor} from '@jobseeker/common-core/lib/components';
import {NotFound} from '@components';
import {metaInfo} from '@configs';
import {getConfigByApp} from '@utils';
import {usePageLoad} from '@hooks';
import {NOTFOUND} from '@constants';

const NotFoundPage = () => {
  const metaData = getConfigByApp(metaInfo, NOTFOUND, false, '404');

  usePageLoad(NOTFOUND);

  return (
    <Interceptor metaInfo={metaData['app'][NOTFOUND]}>
      <NotFound />
    </Interceptor>
  );
};

export default NotFoundPage;
