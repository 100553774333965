import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

const usePageLoad = (page: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'AppContext/set_current_page',
      payload: {page: page},
    });
  }, [page, dispatch]);
};

export default usePageLoad;
